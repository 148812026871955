// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-foot-id-resultado-cliente-jsx": () => import("./../../../src/components/FootId/Resultado/cliente.jsx" /* webpackChunkName: "component---src-components-foot-id-resultado-cliente-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-comparador-index-jsx": () => import("./../../../src/pages/comparador/index.jsx" /* webpackChunkName: "component---src-pages-comparador-index-jsx" */),
  "component---src-pages-comparador-resultado-jsx": () => import("./../../../src/pages/comparador/resultado.jsx" /* webpackChunkName: "component---src-pages-comparador-resultado-jsx" */),
  "component---src-pages-cps-tennis-index-jsx": () => import("./../../../src/pages/cps-tennis/index.jsx" /* webpackChunkName: "component---src-pages-cps-tennis-index-jsx" */),
  "component---src-pages-cumulus-23-index-jsx": () => import("./../../../src/pages/cumulus-23/index.jsx" /* webpackChunkName: "component---src-pages-cumulus-23-index-jsx" */),
  "component---src-pages-dia-das-maes-index-jsx": () => import("./../../../src/pages/dia-das-maes/index.jsx" /* webpackChunkName: "component---src-pages-dia-das-maes-index-jsx" */),
  "component---src-pages-foot-id-agendamento-jsx": () => import("./../../../src/pages/foot-id/agendamento.jsx" /* webpackChunkName: "component---src-pages-foot-id-agendamento-jsx" */),
  "component---src-pages-foot-id-index-jsx": () => import("./../../../src/pages/foot-id/index.jsx" /* webpackChunkName: "component---src-pages-foot-id-index-jsx" */),
  "component---src-pages-foot-id-r-jsx": () => import("./../../../src/pages/foot-id/r.jsx" /* webpackChunkName: "component---src-pages-foot-id-r-jsx" */),
  "component---src-pages-foot-id-resultado-jsx": () => import("./../../../src/pages/foot-id/resultado.jsx" /* webpackChunkName: "component---src-pages-foot-id-resultado-jsx" */),
  "component---src-pages-foot-id-vendedor-jsx": () => import("./../../../src/pages/foot-id/vendedor.jsx" /* webpackChunkName: "component---src-pages-foot-id-vendedor-jsx" */),
  "component---src-pages-gel-cumulus-24-index-jsx": () => import("./../../../src/pages/gel-cumulus24/index.jsx" /* webpackChunkName: "component---src-pages-gel-cumulus-24-index-jsx" */),
  "component---src-pages-gel-kinsei-index-jsx": () => import("./../../../src/pages/gel-kinsei/index.jsx" /* webpackChunkName: "component---src-pages-gel-kinsei-index-jsx" */),
  "component---src-pages-gel-nimbus-23-index-jsx": () => import("./../../../src/pages/gel-nimbus23/index.jsx" /* webpackChunkName: "component---src-pages-gel-nimbus-23-index-jsx" */),
  "component---src-pages-gel-nimbus-24-index-jsx": () => import("./../../../src/pages/gel-nimbus24/index.jsx" /* webpackChunkName: "component---src-pages-gel-nimbus-24-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-lojas-index-jsx": () => import("./../../../src/pages/lojas/index.jsx" /* webpackChunkName: "component---src-pages-lojas-index-jsx" */),
  "component---src-pages-metaspeed-index-jsx": () => import("./../../../src/pages/metaspeed/index.jsx" /* webpackChunkName: "component---src-pages-metaspeed-index-jsx" */),
  "component---src-pages-novablast-2-index-jsx": () => import("./../../../src/pages/novablast-2/index.jsx" /* webpackChunkName: "component---src-pages-novablast-2-index-jsx" */),
  "component---src-pages-quiz-corredor-index-jsx": () => import("./../../../src/pages/quiz/corredor/index.jsx" /* webpackChunkName: "component---src-pages-quiz-corredor-index-jsx" */),
  "component---src-pages-quiz-index-jsx": () => import("./../../../src/pages/quiz/index.jsx" /* webpackChunkName: "component---src-pages-quiz-index-jsx" */),
  "component---src-pages-quiz-resultado-index-jsx": () => import("./../../../src/pages/quiz/resultado/index.jsx" /* webpackChunkName: "component---src-pages-quiz-resultado-index-jsx" */),
  "component---src-pages-quiz-s-resultado-jsx": () => import("./../../../src/pages/quiz/s/resultado.jsx" /* webpackChunkName: "component---src-pages-quiz-s-resultado-jsx" */),
  "component---src-pages-sustainability-index-jsx": () => import("./../../../src/pages/sustainability/index.jsx" /* webpackChunkName: "component---src-pages-sustainability-index-jsx" */),
  "component---src-pages-tecnologias-index-jsx": () => import("./../../../src/pages/tecnologias/index.jsx" /* webpackChunkName: "component---src-pages-tecnologias-index-jsx" */),
  "component---src-pages-totem-templates-asics-back-jsx": () => import("./../../../src/pages/totem/templates/asics-back.jsx" /* webpackChunkName: "component---src-pages-totem-templates-asics-back-jsx" */),
  "component---src-pages-totem-templates-cadastro-jsx": () => import("./../../../src/pages/totem/templates/cadastro.jsx" /* webpackChunkName: "component---src-pages-totem-templates-cadastro-jsx" */),
  "component---src-pages-totem-templates-comparador-jsx": () => import("./../../../src/pages/totem/templates/comparador.jsx" /* webpackChunkName: "component---src-pages-totem-templates-comparador-jsx" */),
  "component---src-pages-totem-templates-comparador-resultado-jsx": () => import("./../../../src/pages/totem/templates/comparador-resultado.jsx" /* webpackChunkName: "component---src-pages-totem-templates-comparador-resultado-jsx" */),
  "component---src-pages-totem-templates-index-jsx": () => import("./../../../src/pages/totem/templates/index.jsx" /* webpackChunkName: "component---src-pages-totem-templates-index-jsx" */),
  "component---src-pages-totem-templates-menu-jsx": () => import("./../../../src/pages/totem/templates/menu.jsx" /* webpackChunkName: "component---src-pages-totem-templates-menu-jsx" */),
  "component---src-pages-totem-templates-oferta-jsx": () => import("./../../../src/pages/totem/templates/oferta.jsx" /* webpackChunkName: "component---src-pages-totem-templates-oferta-jsx" */),
  "component---src-pages-totem-templates-ofertas-interna-jsx": () => import("./../../../src/pages/totem/templates/ofertas-interna.jsx" /* webpackChunkName: "component---src-pages-totem-templates-ofertas-interna-jsx" */),
  "component---src-pages-totem-templates-ofertas-jsx": () => import("./../../../src/pages/totem/templates/ofertas.jsx" /* webpackChunkName: "component---src-pages-totem-templates-ofertas-jsx" */),
  "component---src-pages-totem-templates-pesquisa-satisfacao-jsx": () => import("./../../../src/pages/totem/templates/pesquisa-satisfacao.jsx" /* webpackChunkName: "component---src-pages-totem-templates-pesquisa-satisfacao-jsx" */),
  "component---src-pages-totem-templates-quick-scan-jsx": () => import("./../../../src/pages/totem/templates/quick-scan.jsx" /* webpackChunkName: "component---src-pages-totem-templates-quick-scan-jsx" */),
  "component---src-pages-totem-templates-quiz-jsx": () => import("./../../../src/pages/totem/templates/quiz.jsx" /* webpackChunkName: "component---src-pages-totem-templates-quiz-jsx" */),
  "component---src-pages-totem-templates-quiz-resultado-jsx": () => import("./../../../src/pages/totem/templates/quiz-resultado.jsx" /* webpackChunkName: "component---src-pages-totem-templates-quiz-resultado-jsx" */)
}

